import {FaGithub, FaTwitter, FaInstagram} from 'react-icons/fa'
export default[
    {
        url:"https://github.com/vanelk",
        Icon: FaGithub
    },
    {
        url:"https://twitter.com/_vanelk",
        Icon: FaTwitter
    },
    {
        url:"https://instagram.com/_vanelk",
        Icon: FaInstagram
    }
]