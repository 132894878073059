import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";

const query = graphql`
  {
    site {
      siteMetadata {
        author
        siteDescription
        siteTitle
        image
      }
    }
  }`

export default function SEO({ title, description }) {
    const { site } = useStaticQuery(query);
    const {
        author,
        siteDescription,
        siteTitle,
        image
    } = site.siteMetadata;
    return (
        <Helmet htmlAttributes={{ lang: "en" }} title={`${title} – ${siteTitle}`}>
            <meta charSet="utf-8" />
            <meta name="author" content={author}/>
            <meta name="image" content={image}/>
            <meta name="description" content={description || siteDescription} />
        </Helmet>
    )
}