const data = [
    {
        "text": "Home",
        "url":"/"
    },
    {
        "text":"About",
        "url":"/about/"
    },
    {
        "text":"Blog",
        "url":"/blog/"
    },
    {
        "text":"Contact",
        "url":"/contact/"
    }
]
export default data;