import React from 'react';
import {Link} from 'gatsby';
import navlinks from '../constants/links';
import soclinks from '../constants/socials'
import {FaArrowUp} from 'react-icons/fa'
import HashLink from './HashLink';
export default function Footer() {
    return (
        <footer className="footer">
            <div className="grid">
                <div className="col">
                    <h5>Quick Links</h5>
                    <ul>
                    {
                        navlinks.map(({text, url}, i)=>{
                            return (
                                <li key={i}>
                                    <Link to={url}>{text}</Link>
                                </li>
                            )
                        })
                    }
                    </ul>
                </div>
                <div className="col">
                    <h5>Contact</h5>
                    <ul>
                        <li>
                            <Link to="/contact/">Say Hi</Link>
                        </li>
                        <li>
                            <a href="tel:‪513-399-7360‬">(513) 399-7360</a>
                        </li>
                    </ul>
                </div>
                <div className="col">
                    <h5>Follow me</h5>
                    <div className="social-links">
                    {
                        soclinks.map(({url, Icon}, i)=>(
                            <a key={i} target="_blank" rel="noreferrer" href={url}>
                                <Icon></Icon>
                            </a>
                        ))
                    }
                    </div>
                </div>
                <HashLink to="#top" className="top-button">
                    <FaArrowUp></FaArrowUp>
                    Top
                </HashLink>
            </div>
            <p className="copyright-container">
                &copy; 2020 VANEL
            </p>
        </footer>
    )
}
