import React from 'react';
import {Link} from 'gatsby';
import windowGlobal from '../constants/window'; 
export default function HashLink(props) {
    const removeHash = ()=>{
        const {location, history} = windowGlobal;
        setTimeout(()=>{
            history.replaceState('', document.title, location.origin + location.pathname + location.search);
        }, 5);
    }
    return (
        <Link onClick={removeHash} {...props}/>
    )
}
