import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby';
import data from '../constants/links';
import Logo from '../assets/images/logo.png';
import windowGlobal from '../constants/window';
export default function NarBar() {
    const [checked, setChecked] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    function changeChecked(e) {
        setChecked(e.target.checked);
    }
    
    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 0);
        }
        windowGlobal.addEventListener("scroll", handleScroll);
        return () => windowGlobal.removeEventListener("scroll", handleScroll);
    }, [])
    return (
        <header id="site-header">
            <nav className={`menu ${scrolled ? 'shrink' : ''}`}>
                <Link to="/">
                    <img alt="logo" className="site-img" src={Logo} />
                </Link>
                <label className="menu-icon" htmlFor="menu-toggle">
                    <input type="checkbox" onClick={changeChecked} name="toggle-menu" id="menu-toggle" />
                    <span className="nav-icon"></span>
                </label>
                <ul className="menu-list" style={{ transform: checked ? 'translateY(0)' : '' }}>
                    {data.map((link, index) => {
                        return (
                            <li key={index} className="menu-list__item">
                                <Link activeClassName="active" className="menu-link" to={link.url}>{link.text}</Link>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        </header>
    )
}
