import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
export default function Layout(props) {
    return (
        <div className="page-container">
            <div id="top"></div>
            <NavBar></NavBar> 
                {props.children}
            <Footer></Footer>
        </div>
    )
}
